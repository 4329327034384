export interface Contact {
  id: string;
  sdrId: string;
  status: string;
  sdrStatus: string;
  startAt: number;
  endsAt: number;
  contactList: [ContactList];
  voiceDropsIds: [string];
  speech: string;
  agents: [string];
  scriptId: string;
  scriptTitle: string;
  flagged: boolean;
}

interface ContactList {
  contactListId: string;
  completed: boolean;
  title: string;
}

class ContactMetadata {
  externalId?: string;
}

export interface ContactInfo {
  id: string;
  accountName: string;
  contactsListId: string;
  notes?: string;
  notesAgent?: string;
  notesSDR?: string;
  flagged: boolean;
  crmId: string;
  email: string;
  name: string;
  phone: string;
  jobTitle: string;
  type: ContactSource;
  meta?: ContactMetadata;
}

export enum ContactSource {
  CSV = 'CSV',
  HUBSPOT = 'HUBSPOT',
  OUTREACH = 'OUTREACH',
}

export class ContactToDisplay {
  'Account Name': string;
  Email: string;
  'Contact Name': string;
  Phone: string;
  'Job Title': string;

  constructor(accountName?: string, email?: string, contactName?: string, phone?: string, jobTitle?: string) {
    this['Account Name'] = accountName !== undefined && accountName !== '' ? accountName : 'Not available';
    this.Email = email !== undefined && email !== '' ? email : 'Not available';
    this['Contact Name'] = contactName !== undefined && contactName !== '' ? contactName : 'Not available';
    this.Phone = phone !== undefined && phone !== '' ? phone : 'Not available';
    this['Job Title'] = jobTitle !== undefined && jobTitle !== '' ? jobTitle : 'Not available';
  }
}

export class NotesToDisplay {
  Notes: string;
  'User Notes': string;
  'Agent Notes': string;
  constructor(notes?: string, notesSdr?: string, notesAgent?: string) {
    this.Notes = notes !== undefined && notes !== '' ? notes : 'Not available notes';
    this['User Notes'] = notesSdr !== undefined && notesSdr !== '' ? notesSdr : 'Not available notes';
    this['Agent Notes'] = notesAgent !== undefined && notesAgent !== '' ? notesAgent : 'Not available notes';
  }
}
