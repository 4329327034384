import React, { type ReactElement, useState } from 'react';
import useSalesforceViewModel from './SalesforceConfig.viewmodel';
import { showUIToast, UI_TOAST_TYPES } from '@/src/core/ui/UIToast';
import DeleteAccountModal from '@/src/pages/Settings/Shared/Components/DeleteAccountModal';
import IntegrationUser from '@/src/pages/Settings/Shared/Components/integrations/IntegrationUser.component';
import salesforceLogo from '@/src/assets/icons/salesforce.svg';
import Loader from '@/src/core/ui/UILoader';

export const SalesForceView = (): ReactElement => {
  const {
    accounts,
    loading,
    configuring,
    stateMessage,
    loadingMessage,
    handleConnect,
    handleCancel,
    removeSalesforceUser,
  } = useSalesforceViewModel();
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, nonce: '' });

  const connectToSalesforce = async (): Promise<void> => {
    const response = await handleConnect();
    showUIToast({
      type: response.status === 201 ? UI_TOAST_TYPES.SUCCESS : UI_TOAST_TYPES.ERROR,
      text: response.message,
    });
  };

  const deleteAccount = async (accountId: string): Promise<void> => {
    const response = await removeSalesforceUser(accountId);
    showUIToast({
      type: response.status === 204 ? UI_TOAST_TYPES.SUCCESS : UI_TOAST_TYPES.ERROR,
      text: response.message,
    });
    if (response.status === 204) {
      setDeleteModal({ isOpen: false, nonce: '' });
    }
  };

  return (
    <div className="relative flex flex-col items-center gap-4 min-h-[395px]">
      <DeleteAccountModal
        isOpen={deleteModal.isOpen}
        onClose={() => {
          setDeleteModal({ isOpen: false, nonce: '' });
        }}
        handleDelete={() => {
          void deleteAccount(deleteModal.nonce);
        }}
      />

      {loading ? (
        <Loader message={loadingMessage} />
      ) : (
        <>
          <button
            className="justify-self-center bg-transparent border-none p-0 cursor-pointer"
            disabled={configuring}
            onClick={() => {
              void connectToSalesforce();
            }}
          >
            <img alt="Salesforce" src={salesforceLogo as unknown as string} width="120" />
          </button>

          {configuring && (
            <button className="text-blue-500" onClick={handleCancel}>
              Cancel Configuration
            </button>
          )}

          <div
            className={`w-full grid grid-cols-2 gap-2 ${
              accounts.length > 10 ? 'max-h-[calc(82px_*_5_+_0.5rem_*_5)] overflow-y-scroll' : ''
            }`}
          >
            {accounts.map((user) => {
              const { id, email, name, nonce } = user as { id: string; email: string; name?: string; nonce: string };
              return (
                <IntegrationUser
                  key={nonce}
                  remove={() => {
                    setDeleteModal({ isOpen: true, nonce });
                  }}
                  id={id}
                  email={email}
                  name={name ?? ''}
                  nonce={nonce}
                  type="salesforce"
                />
              );
            })}
          </div>

          <div className="text-gray-500">{stateMessage}</div>
        </>
      )}
    </div>
  );
};
