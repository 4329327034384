import React, { useCallback, useContext, useEffect, useState } from "react";
import Layout from "../../components/shared/Layout";
import { useNavigate, useLocation } from "react-router-dom";
import { ReadyContainer, CallContainer } from "../../components/dialerSDR";
import { HomepageMessageCircle } from "../../components/shared/HomepageElements";
import axios from "axios";
import routes from "../../constants/routes";
import { Connection, Device } from "twilio-client";
import {
  Action,
  CallSession,
  CallSessionAction,
  LocationState,
  TwilioTokenSuccessResponse,
  UpdateCallSessionStatus,
} from "../../components/shared/CallSession";
import { ThemeProviderWrapperContext } from "../../components/app/ThemeProviderWrapper";
import { Contact } from "../../components/shared/Contact";
import getByMultipleStatusAndSdrIdAsync from "../../api/CallSession/getByMultipleStatusAndSdrIdAsync";
import { colors } from "../../core/theme";
import updateCallSessionAsync from "../../api/CallSession/updateCallSessionAsync";
import startCallSessionAsync from "../../api/CallSession/startCallSessionAsync";
import freeSdrStatusOnCallSessionAsync from "../../api/CallSession/freeSdrStatusOnCallSessionAsync";
import busySdrStatusOnCallSessionAsync from "../../api/CallSession/busySdrStatusOnCallSessionAsync";
import deleteSDRCallLogAndUpdateCallLogAsync from "../../api/CallLog/deleteSDRCallLogAndUpdateCallLogAsync";
import { Button, Flex, Text } from "../../core/ui";
import Play from "../../assets/icons/play_white.svg";
import Pause from "../../assets/icons/pause.svg";
import styled from "styled-components";
import Modal from "../../components/shared/Modal";
import { PAUSED, FREE } from "../../constants/theme";
import DispositionsModal from "../../components/dispositions/DispositionsModal";
import tabTitle from "../../utils/updateTitle";
import { UICol, UIRow } from "../../core/ui/UIStructures";
import { showUIToast, UI_TOAST_TYPES } from "../../core/ui/UIToast";
import getContactListAsync from "../../api/ContactList/getContactListAsync";
import { ContactList } from "../../components/shared/ContactList";
import updateSyncJobAsync from "../../api/Sync/updateSyncJobAsync";
import { UserProviderContext } from "../../components/app/UserProvider";
import ErrorBoundary from "../../components/ErrorBoundary";
import useMicrophone from "../../core/hooks/useMicrophone";
import MicrophoneSetupModal from "./components/MicrophoneSetupModal";
import updateCallLogAsync from "../../api/CallLog/updateCallLogAsync";
import useRetryTimer from "../../core/hooks/useRetryTimer";
import getCurrentNumberUser from "../../api/CallerId/getCurrentNumberUser";
import getByIdAsync from "../../api/CallSession/getByIdAsync";
import { deleteSdrLogAsync } from "../../api/SdrLogs/deleteSdrLogAsync";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faContactBook, faPhone } from "@fortawesome/free-solid-svg-icons";
import { useOutletContext } from "react-router-dom";
import { OutletContextProps } from "../../components/RoutesLayout";
import getTwilioToken from '../../api/Twilio/getTwilioToken';
import CustomSounds from "../../../src-v2/Domain/enums/Twilio/custom-sounds";
import { useLogger } from "../../components/app/LoggerProvider";
import getNumberOfParticipants from "../../../src-v2/Data/DataSource/edge/twilio/getNumberOfParticipants";
import { SdrStatus, SessionStatus } from "../../../src-v2/Domain/enums/CallSessions/callSessionsStatus";

const window = globalThis as any;

const Img = styled.img`
  width: 2.5rem;
  height: 1rem;
`;

const TopButton = styled(Button)`
  margin-right: 1rem;
  &:disabled {
    opacity: 0.4;
  }
`;

const SecondaryButton = styled(Button)`
  color: #a6a8aa !important;
  background: #efefef !important;
`;

const ButtonCallSessionModal = styled(Button)`
  width: 40% !important;
`;

const SecondaryButtonCallSessionModal = styled(SecondaryButton)`
  width: 40% !important;
`;

const options = [
  { value: "SA", label: "Set Appointment" },
  { value: "OO", label: "Opt Out" },
  { value: "LVM", label: "Left Voicemail" },
  { value: "PR", label: "Phone Refusal (hangup)" },
  { value: "GC", label: "Gatekeeper Conversation" },
  { value: "RAC", label: "Referred to another contact" },
  { value: "NLC", label: "No Longer With Company" },
  { value: "DMC", label: "Decision Maker Conversation" },
  { value: "OTR", label: "Other" },
];

const syncTypes: string[] = ["OUTREACH", "HUBSPOT", "CUSTOM_HUBSPOT"];

const DialerSDR = (): JSX.Element => {
  const { twilioDevice, setTwilioDevice, userSession, handleRoutesLogout, isFirstRender, setIsFirstRender, currentCallSession, setCurrentCallSession, intervals, setIntervals } = useOutletContext<OutletContextProps>();
  const [step, setStep] = useState<number>(1);
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const state = location.state as LocationState;
  const setup = urlParams.get("setup");
  const [callSession, setCallSession] = useState<CallSession | null>(
    setup ? state?.callSession : ({} as CallSession)
  );
  const [contactList, setContactList] = useState<ContactList>(
    {} as ContactList
  );
  const [hasCallSessionInProgress, setCallSessionInProgress] =
    useState<boolean>(false);
  const [currentContact, setCurrentContact] = useState<Contact | null>(null);
  const [callId, setCallId] = useState<string>("");
  const [friendlyNameVM, setfriendlyNameVM] = useState<string>("");
  const [muted, setMuted] = useState<boolean>(false);
  const [hasCallSession, setHasCallSession] = useState<boolean>(false);
  const [onPhone, setOnPhone] = useState<boolean>(false);
  const [connection, setConnection] = useState<Connection>({} as Connection);
  const [sdrStatus, setStatus] = useState<string>("free");
  const [conferenceFriendlyName, setConferenceFriendlyName] =
    useState<string>("");
  const { changeTheme } = useContext(ThemeProviderWrapperContext);
  const [sessionIsPause, setSessionIsPause] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [callStatus, setCallStatus] = useState<string>("");
  const [showModalDisposition, setShowModalDisposition] = useState(false);
  const [showAdditionalNotes, setShowAdditionalNotes] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    value: "",
    label: "",
  });
  const [notesSDR, setNotesSDR] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [callIdDisposition, setCallIdDisposition] = useState("");
  const [submittedInfo, setSubmittedInfo] = useState<boolean>(true);
  const [submittedDisabled, setSubmittedDisabled] = useState<boolean>(true);
  const [errorBoundery, setErrorBoundery] = useState<boolean>(false);
  const [currentNumber, setCurrentNumber] = useState("");
  const [modalCallSession, setModalCallSession] = useState<boolean>(false);
  const [pausedCallSession, setPausedCallSession] = useState<string>("");
  const [queueElements, setQueueElements] = useState<number>(0);
  const { setupMicrophonePermissions, isMicrophoneAllowed } = useMicrophone();
  const [twilioToken, setTwilioToken] = useState("");
  const { logger } = useLogger();
  tabTitle("Tendril Connect | Home");

  const retryTimerHook = useRetryTimer();
  const { isWaitingRetry, startRetryTimer, retryDelay } = retryTimerHook;
  const navigate = useNavigate();

  const configureCallSession = useCallback(
    async (callSession) => {
      if (isWaitingRetry) {
        return;
      }

      if (!isMicrophoneAllowed) {
        await setupMicrophonePermissions({ showToast: true });
        return;
      }

      if (!callSession.contactList) {
        navigate("/contacts?setup=1", {
          state: { callSession: callSession },
        });
      } else if (!callSession.voiceDropsIds) {
        navigate("/voice?setup=1", {
          state: { callSession: callSession },
        });
      } else if (!callSession.speech) {
        navigate("/script?setup=1", {
          state: { callSession: callSession },
        });
      } else {
        callSession.status = "started";
        callSession.sdrId = userSession.userId;
        callSession.sdrStatus = "free";
        setCallSession(callSession);
        setStep(2);
        console.log(callSession);
      }
    },
    [navigate, userSession.userId, isMicrophoneAllowed]
  );

  const validateCallSessionInfo = async (contactListId) => {
    const { contactList, error } = await getContactListAsync(contactListId);

    if (error) {
      startRetryTimer();
      showUIToast({
        type: "error",
        text: "Something happened resuming your paused call session. Please try again later.",
      });
      setStep(2);
      setErrorBoundery(true);
      throw new Error(
        "Something happened resuming your paused call session. Please try again later."
      );
    }

    if (contactList && !error) {
      setContactList(contactList);
      setCallStatus("started");
      setStep(3);
      changeTheme(FREE);
    }
  };

  const configurePausedCallSession = async () => {
    if (!hasCallSession || !currentCallSession) {
      return;
    }

    if (currentCallSession.status === "started") {
      await validateCallSessionInfo(
        currentCallSession.contactList[0]?.contactListId
      );
    } else {
      await updateCallSessionStatus(currentCallSession.id, SessionStatus.STARTED, SdrStatus.FREE, {
        action: Action.STARTED,
        userId: userSession.userId
      });
      await validateCallSessionInfo(
        currentCallSession.contactList[0]?.contactListId
      );
    }
  };

  const configureNewCallSession = async (newCallSession: CallSession) => {
    if (!callSession) {
      return;
    }

    setCallSession(newCallSession);

    const { responseCreateCallSession, error } = await startCallSessionAsync({
      data: newCallSession,
    });

    if (error) {
      if (error?.data?.callSessionId) {
        setPausedCallSession(error?.data?.callSessionId);
        setModalCallSession(true);
        return;
      }
      startRetryTimer();
      showUIToast({
        type: "error",
        text: error.message,
      });
      setStep(2);
      setErrorBoundery(true);
      throw new Error(error.message);
    }

    if (responseCreateCallSession) {
      const { callSession, queueLength } = responseCreateCallSession;
      setQueueElements(queueLength);
      setCurrentCallSession(callSession);
      await configureContactList(callSession.contactList[0]?.contactListId);

      setCallStatus("started");
      setStep(3);
      changeTheme(FREE);
    }
  };

  const configureContactList = async (contactListId: string) => {
    const { contactList, error } = await getContactListAsync(contactListId);

    if (error) {
      const errorMessage =
        "Something happened getting the contact list. Please try again later." ||
        error.message;
      startRetryTimer();
      showUIToast({
        type: "error",
        text: errorMessage,
      });
      setStep(2);
      setErrorBoundery(true);
      throw new Error(errorMessage);
    }

    contactList && setContactList(contactList);
  };

  const startCallSession = async () => {
    if (!isMicrophoneAllowed) {
      await setupMicrophonePermissions({ showToast: true });
      return;
    }

    if (isWaitingRetry) {
      showUIToast({
        type: "info",
        text: `Please wait until the retry timer is finished.`,
      });
      return;
    }

    startRetryTimer();

    if (hasCallSession && !!currentCallSession) {
      await configurePausedCallSession();
      return;
    }

    const customCallSession = {
      ...callSession,
      startAt: new Date().toUTCString(),
    } as CallSession;

    if (customCallSession?.contactList) {
      customCallSession.contactList = [
        {
          completed: customCallSession.contactList[0].completed,
          contactListId: customCallSession.contactList[0].contactListId,
        },
      ];
    }

    await configureNewCallSession(customCallSession);
  };

  const hangup = () => {
    connection.disconnect();
    setOnPhone(false);
    setStatus("free");
    setCallId("");
    setSubmittedDisabled(true);
  };

  const toggleMute = (state: boolean) => {
    setMuted(state);
    twilioDevice.activeConnection()?.mute(state);
    showUIToast({ type: "info", text: state ? "Muted" : "Unmuted" });
  };

  const handleOutboundCallAnswer = async () => {
    const { callSession, error } = await busySdrStatusOnCallSessionAsync({
      sdrId: userSession.userId,
    });

    if (error && error.status !== 423) {
      // TODO: SHOW UI TOAST
      twilioDevice.disconnectAll();
      setErrorBoundery(true);
      throw new Error(error?.message);
    }

    if (callSession) {
      setCurrentCallSession(callSession);
    }
  };

  const handleOutboundCallHangup = async (callId, callSessionId) => {
    const { callSession, error } = await freeSdrStatusOnCallSessionAsync({
      callSessionId: callSessionId,
    });

    if (error) {
      // TODO: SHOW UI TOAST
      setErrorBoundery(true);
      throw new Error(error.message);
    }

    if (callSession) {
      setCurrentCallSession(callSession);
    }

    const { callLog, deleteError } =
      await deleteSDRCallLogAndUpdateCallLogAsync({
        sdrId: userSession.userId,
        callId: callId,
      });

    if (deleteError) {
      setErrorBoundery(true);
      throw new Error(deleteError.message);
    }

    if (callLog && callSession?.status !== "paused") {
      showUIToast({
        type: "info",
        text: "Transfers allowed.",
      });
    }
  };

  const retrieveTransferredCallLog = async (callId) => {
    try {
      const callLogResponse = await axios.get(routes.get.callLogs.callLogNest + `sdr/${callId}`);
      const contact = callLogResponse.data.contact;
      setCallIdDisposition(callLogResponse.data.callId);
      const contactRes = await axios.get(routes.get.contacts.byContactIdNest + `/${contact._id ? contact._id : contact.id}`);
      setCurrentContact(contactRes.data);
    } catch (e) {
      showUIToast({
        type: "error",
        text: "Error getting sdr call log, try again later.",
      });
      setErrorBoundery(true);
      throw new Error(e.message);
    }
  };

  const updateCallSessionStatus = async (
    id: string | undefined,
    status: string,
    sdrStatus: string,
    action?: CallSessionAction | null,
    isFirstRender = false
  ) => {
    
    let data: UpdateCallSessionStatus = { status: status, sdrStatus: sdrStatus };

    if (action) {
      data.action = action;
    }

    const { callSession, error } = await updateCallSessionAsync({
      id: id,
      data,
    });

    if (error) {
      setErrorBoundery(true);
      throw new Error(error.message);
    }

    if (callSession) {
      setupAfterUpdateCallSessionStatus(callSession, isFirstRender);
    }
    if (status === "finished" && syncTypes.includes(contactList!.type)) {
      const { job, error } = await updateSyncJobAsync(
        contactList.type,
        contactList.nonce,
        userSession.organizationId,
        contactList.id
      );

      if (error) {
        showUIToast({
          type: "error",
          text: error?.message,
        });
        setErrorBoundery(true);
        throw new Error(error.message);
      }
    }
  };

  const setupAfterUpdateCallSessionStatus = (callSession, isFirstRender) => {
    setCurrentCallSession(callSession);
    if (callSession.status === "finished") {
      setCurrentCallSession(undefined);
      setHasCallSession(false);
      setStep(1);
    } else if (isFirstRender) {
      setHasCallSession(true);
      setStep(2);
    } else {
      setHasCallSession(true);
      setStep(3);
    }
  };

  const getCallSessionByStatusAndSdrId = async (
    oldStatus: string[],
    newStatus: string,
    isFirstRender = false
  ) => {
    const { response, error } = await getByMultipleStatusAndSdrIdAsync({
      status: oldStatus,
      sdrId: userSession.userId,
    });

    if (error) {
      // TODO: SHOW UI TOAST
      setErrorBoundery(true);
      throw new Error(error.message);
    }

    if (response) {
      const { callSession, queueLength } = response;
      setCallSessionInProgress(true);
      setQueueElements(queueLength);
      const callSessionStatus = callSession.status;
      if (callSessionStatus === SessionStatus.PAUSED) {
        setupAfterUpdateCallSessionStatus(callSession, isFirstRender);
      } else {
        await updateCallSessionStatus(callSession.id, newStatus, newStatus === SessionStatus.PAUSED ? SdrStatus.BUSY: SdrStatus.FREE, null, isFirstRender);
      }
    }

    return response?.callSession?.id;
  };

  const handleEndSession = async () => {
    setModal(false);
    updateCallSessionStatus(currentCallSession?.id, SessionStatus.FINISHED, SdrStatus.FREE, {
      action: Action.FINISHED,
      userId: userSession.userId
    }).then();
    setSessionIsPause(false);
    changeTheme("free");
    setCallSession({} as CallSession);
    setCurrentCallSession(undefined);
    showUIToast({
      type: "info",
      text: "Finished call session.",
    });
    if (intervals["sideBar"]) clearInterval(intervals["sideBar"]);
    if (intervals["sideBarCurrentSessionStats"])
      clearInterval(intervals["sideBarCurrentSessionStats"]);
    if (intervals["dialingStatus"]) clearInterval(intervals["dialingStatus"]);
  };

  const   handlePausePlaySession = async (id?) => {
    if (!submittedInfo) {
      showUIToast({
        type: UI_TOAST_TYPES.WARNING,
        text: "You can't restart the session until you submit the contact form.",
      });
      return;
    };

    setSessionIsPause(!sessionIsPause);
    !sessionIsPause ? changeTheme(PAUSED) : changeTheme("free");

    if (!sessionIsPause) {
      setCallStatus("paused");
      await updateCallSessionStatus(
        id === undefined ? currentCallSession?.id : id,
        SessionStatus.PAUSED,
        SdrStatus.BUSY,
        {
          action: Action.PAUSED,
          userId: userSession.userId
        }
      );
    } else {
      setCallStatus("started");

      const { success, error } = await deleteSdrLogAsync(currentCallSession?.sdrId);
    
      if (!success && error && error.status  !== 404) {
        logger.error(error.message, {
          componentName: DialerSDR.name,
          userId: userSession.userId
        });
      };
      
      if(success){
        logger.info(`SdrLog for SDR with id: ${userSession.userId} was successfully deleted. Transfer unlocked.`, {
        componentName: DialerSDR.name,
        userId: userSession.userId
      });
      };

      await updateCallSessionStatus(
        id === undefined ? currentCallSession?.id : id,
        SessionStatus.STARTED,
        SdrStatus.FREE,
        {
          action: Action.STARTED,
          userId: userSession.userId
        }
      );
      if (currentContact) setCurrentContact(null);
    }

    showUIToast({
      toastId: "dialerSDRPausedStartedToast",
      type: UI_TOAST_TYPES.INFO,
      text: `Session ${!sessionIsPause ? "paused" : "started"}.`,
    });
  };

  useEffect(() => {
    (async () => {
      const { twilioTokenResponse, error } = await getTwilioToken(
        userSession.userId
      );
      const token = twilioTokenResponse?.token;

      if (error || !twilioTokenResponse || !token) {
        showUIToast({
          type: "warning",
          text: "Twilio requires attention. If the problem persists, please contact customer support. ",
        });
        logger.error("Error getting twilio token.", {
          componentName: DialerSDR.name,
          userId: userSession.userId
        })
        return;
      }

      twilioDevice.setup(token, {
        audioConstraints: true, codecPreferences: [Connection.Codec.Opus, Connection.Codec.PCMU], closeProtection: true, fakeLocalDTMF: true, sounds: {
          incoming: CustomSounds.INCOMING,
          outgoing: CustomSounds.OUTGOING,
          disconnect: CustomSounds.DISCONNECT,
        }
      });

      twilioDevice.on("ready", function (device: Device) {
        showUIToast({
          type: "default",
          text: "Connected",
        });
      });

      // Configure event handlers for Twilio Device
      twilioDevice.on("disconnect", async function (connection: Connection) {
        await handleOutboundCallHangup(
          connection.customParameters.get("conferenceFriendlyName"),
          connection.customParameters.get("callSessionId")
        );
        OnCallFinished();
      });
      twilioDevice.on("incoming", async function (connection: Connection) {
        if (onPhone) {
          connection.reject();
          showUIToast({
            type: "warning",
            text: "Rejected incoming transfer.",
          });
        } else {
          showUIToast({
            type: "default",
            text: "Incoming Call",
          });

          if (!connection.customParameters.get("conferenceFriendlyName")) {
            connection.reject();

            logger.error("Incoming call rejected because the conference ID was not found.", {
              componentName: DialerSDR.name,
              userId: userSession.userId,
            });

            return showUIToast({
              type: "warning",
              text: "Incoming call rejected because the conference ID was not found.",
            });
          };

          const { data, success } = await getNumberOfParticipants(connection.customParameters.get("conferenceFriendlyName") || "", userSession.userId);

          if (data < 2 || !success) {
            connection.reject();

            logger.error("Incoming call rejected due to lack of participants.", {
              componentName: DialerSDR.name,
              userId: userSession.userId,
              callId: connection.customParameters.get("conferenceFriendlyName") || "",
              participants: data
            });

            return showUIToast({
              type: "warning",
              text: "Incoming call rejected due to lack of participants.",
            });
          }
          await handleOutboundCallAnswer();
          await retrieveTransferredCallLog(
            connection.customParameters.get("conferenceFriendlyName")
          );
          setConferenceFriendlyName(
            connection.customParameters.get("conferenceFriendlyName") ?? "undefined"
          );
          // console.log(connection.customParameters.get("conferenceFriendlyName"), "fiendlyName")
          // console.log(connection.parameters.CallSid, 'call id 2')
          setCallId(connection.parameters.CallSid);
          setfriendlyNameVM(connection.customParameters.get("conferenceFriendlyName") ?? "undefined");
          setConnection(connection);
          setOnPhone(true);
          setSessionIsPause(true);
          changeTheme(PAUSED);
          setCallStatus("paused");
          setStatus("busy");
          connection.accept();
        }
      });

    })();
    return () => { twilioDevice?.destroy(); };
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && setup === "1") {
      configureCallSession(callSession).finally();
    }

    return () => {
      isMounted = false;
    };
  }, [
    callSession,
    configureCallSession,
    location.state,
    twilioDevice,
    setup,
    userSession.userId,
  ]);

  const logout = () => {
    handleRoutesLogout();
    if (intervals["sideBar"]) clearInterval(intervals["sideBar"]);
    if (intervals["sideBarCurrentSessionStats"])
      clearInterval(intervals["sideBarCurrentSessionStats"]);
    if (intervals["dialingStatus"]) clearInterval(intervals["dialingStatus"]);
  };

  const onDropdownChange = (event) => {
    setSelectedOption(event);
    setIsDisabled(event.value !== "" ? true : false);
    setShowAdditionalNotes(event.value === "OTR" ? true : false);
  };

  const cleanDispositionInfo = () => {
    setNotesSDR("");
    setShowAdditionalNotes(false);
    setShowModalDisposition(false);
    setSelectedOption({
      value: "",
      label: "",
    });
    setIsDisabled(false);
    setSubmittedDisabled(false);
  };

  const handleSelectedOption = async () => {
    if (callIdDisposition && selectedOption.value !== "") {
      const { error } = await updateCallLogAsync({
        callId: callIdDisposition,
        status: "finished",
        notesSDR: notesSDR,
        disposition: selectedOption.value,
      });

      if (error) {
        setShowModalDisposition(false);
        setErrorBoundery(true);
        showUIToast({
          type: "error",
          text: "Error updating disposition.",
        });
      }
    }
    cleanDispositionInfo();
  };

  const sessionUser = userSession.userName
    ? userSession.userName.split(" ")[0]
    : "SDR";

  useEffect(() => {
    let isMounted = true;

    // Validates the current call session status and updates the UI accordingly
    if (isMounted) {
      const fetchCallSession = async () => {
        const { response } = await getByMultipleStatusAndSdrIdAsync({
          status: ["started", "paused"],
          sdrId: userSession.userId,
        });

        if (response) {

          const { success, error } = await deleteSdrLogAsync(userSession.userId);

          if (!success && error && error.status !== 404) {
            logger.error(error.message, {
            componentName: DialerSDR.name,
            userId: userSession.userId
            });
          };

          const { callSession, queueLength } = response;

          setQueueElements(queueLength);

          callSession && setupAfterUpdateCallSessionStatus(callSession, true);
        }
      };
      fetchCallSession();
    }
    return () => {
      isMounted = false;
    };
  }, [userSession.userId]);

  useEffect(() => {
    (async () => {
      const { currentNumber: number, error: errorNumber } =
        await getCurrentNumberUser();

      if (errorNumber || !number) {
        showUIToast({
          type: "error",
          text: "Error getting call session preview, try again later.",
        });
      } else {
        setCurrentNumber(number);
      }
    })();
  }, []);

  function OnCallFinished() {
    setOnPhone(false);
    setCallId("");
    setStatus("free");
    setShowModalDisposition(true);
    setMuted(false);
    setConnection({} as Connection);
    setConferenceFriendlyName("");
  }

  const handleContinuePausedSession = async () => {
    setModalCallSession(false);
    setErrorBoundery(false);
    const { callSession } = await getByIdAsync(pausedCallSession);
    setCurrentCallSession(callSession);
    setHasCallSession(true);
    await configurePausedCallSession();
    setPausedCallSession("");
  };

  const handleFinishPausedSession = async () => {
    setModalCallSession(false);
    setErrorBoundery(false);
    await updateCallSessionStatus(pausedCallSession, SessionStatus.STARTED, SdrStatus.FREE, { action: Action.FINISHED, userId: userSession.userId }, false);
    await configureNewCallSession(callSession!);
    setPausedCallSession("");
  };

  useEffect(() => {
    if (callStatus === "paused"){
      localStorage.setItem("callStatus", "paused");
    } else if (callStatus === "started"){
      localStorage.setItem("callStatus", "started");
    } else {
      localStorage.removeItem("callStatus");
    }
  },[callStatus])

  return (
    <Layout
      sidebar
      handleLogout={logout}
      user={userSession.userName}
      intervals={intervals}
      setIntervals={setIntervals}
      callStatus={currentCallSession?.status}
      sdrStatus={sdrStatus}
    >
      <MicrophoneSetupModal />

      {step === 1 && (
        <HomepageMessageCircle
          message={`Welcome ${sessionUser}! Select your tools to get started`}
          buttonMessage={
            isWaitingRetry ? `Retry in ${retryDelay} sec.` : "Configure Session"
          }
          handleClick={() => configureCallSession(callSession)}
        />
      )}

      {step === 2 && !onPhone && (
        <>
          <ReadyContainer
            user={sessionUser}
            onStart={async () => await startCallSession()}
            callSession={callSession}
            hasCallSession={hasCallSession}
            retryTimer={retryTimerHook}
          />
          <Modal
            state={modalCallSession}
            setState={setModalCallSession}
            title={"Error creating a new call session"}
            showHeader={true}
            showOverlay={true}
            modalPosition={"center"}
            padding={"20px"}
            showCloseBtn={false}
          >
            <>
              <p>
                It seems that you have another session running, do you want to
                resume it or do you want to finish it and create a new one?
              </p>
              <Flex flexDirection="row" justifyContent="space-between">
                <SecondaryButtonCallSessionModal
                  onClick={handleContinuePausedSession}
                >
                  {" "}
                  Resume session{" "}
                </SecondaryButtonCallSessionModal>
                <ButtonCallSessionModal onClick={handleFinishPausedSession}>
                  {" "}
                  Create session{" "}
                </ButtonCallSessionModal>
              </Flex>
            </>
          </Modal>
        </>
      )}

      {step === 3 && (
        <>
          <UIRow gap={1}>
            <UICol sm={12} justifyContent="flex-end">
              <UICol sm={4} alignItems="center">
                <p className="text-white text-md">
                  Contacts left in queue:
                  <span className="flex font-semibold text-lg items-center">
                    <FA icon={faContactBook} className="mx-1" />
                    {queueElements}
                  </span>
                </p>
              </UICol>
              <UICol alignItems="center">
                <p className="text-white text-md">
                  Phone number used:
                  <span className="flex font-semibold text-lg items-center">
                    <FA icon={faPhone} className="mx-1" />
                    {currentNumber}
                  </span>
                </p>
              </UICol>
              <UICol sm={4} justifyContent="flex-end" alignItems="center">
                <TopButton
                  data-qa-id={"pausePlayBtn"}
                  onClick={() => handlePausePlaySession(currentCallSession?.id)}
                  bg={
                    sessionIsPause
                      ? `linear-gradient(90deg, ${colors.lightBlue}, ${colors.darkBlue})`
                      : colors.darkGray
                  }
                  disabled={onPhone}
                >
                  {sessionIsPause ? (
                    <Img src={Play} alt="Play icon" />
                  ) : (
                    <Img src={Pause} alt="Pause icon" />
                  )}
                </TopButton>
                <TopButton
                  data-qa-id={"endSessionBtn"}
                  onClick={() => setModal(true)}
                  bg={`linear-gradient(90deg, ${colors.yellow}, ${colors.orange});`}
                >
                  <Text
                    color={colors.white}
                    fontWeight="600"
                    fontSize={16}
                  >
                    End session
                  </Text>
                </TopButton>
              </UICol>
            </UICol>
            <UICol>
              <ErrorBoundary
                fallBackComponent={<>NO, MURIÓ!!</>}
                resetCondition={
                  currentCallSession && currentContact && twilioDevice
                }
                error={errorBoundery}
              >
                <CallContainer
                  sdrStatus={sdrStatus}
                  hangup={hangup}
                  setSdrStatus={setStatus}
                  callSessionId={currentCallSession?.id}
                  contactList={currentCallSession?.contactList}
                  voiceDropsIds={currentCallSession?.voiceDropsIds}
                  currentContact={currentContact}
                  setCurrentContact={setCurrentContact}
                  onPhone={onPhone}
                  speech={currentCallSession?.speech}
                  twilioDevice={twilioDevice}
                  callId={callId}
                  muted={muted}
                  toggleMute={toggleMute}
                  conferenceFriendlyName={conferenceFriendlyName}
                  isPaused={sessionIsPause}
                  intervals={intervals}
                  setIntervals={setIntervals}
                  submittedInfo={submittedInfo}
                  setSubmittedInfo={setSubmittedInfo}
                  cleanDispositionInfo={cleanDispositionInfo}
                  submittedDisabled={false}
                  setSubmittedDisabled={setSubmittedDisabled}
                  queueElements={queueElements}
                  setQueueElements={setQueueElements}
                  friendlyNameVM={friendlyNameVM}
                />
              </ErrorBoundary>
            </UICol>
          </UIRow>
          <Modal
            state={modal}
            setState={setModal}
            title={"End session"}
            showHeader={true}
            showOverlay={true}
            modalPosition={"center"}
            padding={"20px"}
            showCloseBtn={true}
          >
            <>
              <p>Are you sure you want to end this call session?</p>
              <Flex flexDirection="row" justifyContent="space-between">
                <SecondaryButton
                  onClick={() => {
                    setModal(false);
                  }}
                >
                  {" "}
                  Cancel{" "}
                </SecondaryButton>
                <button className="secondary" onClick={handleEndSession}>
                  {" "}
                  End{" "}
                </button>
              </Flex>
            </>
          </Modal>
          <DispositionsModal
            isOpen={showModalDisposition}
            setOpen={setShowModalDisposition}
            selectedOption={selectedOption}
            options={options}
            additionalNotes={notesSDR}
            setAdditionalNotes={setNotesSDR}
            showAdditionalNotes={showAdditionalNotes}
            onDropdownChange={onDropdownChange}
            onSubmit={handleSelectedOption}
            isDisabled={isDisabled}
          />
        </>
      )}
    </Layout>
  );
};

export default DialerSDR;
