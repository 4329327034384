import axios, {AxiosResponse, InternalAxiosRequestConfig} from "axios";
import {ILogger} from "@/src-v2/Shared/logger/logger.service";
import {toast} from "@components/ui/use-toast";


const checkConnectionPerformance = (logger: ILogger)=>  {
    let startTime: number;
    let endTime: number;
    let requestUrl: string | undefined;
    let timeElapsed;

    const userSession = JSON.parse(window.localStorage.getItem("contextUser") || "{}")

        axios.interceptors.request.use((request: InternalAxiosRequestConfig):any=> {
        startTime =  new Date().getTime();
        requestUrl = request.url;
        return request;
    });

    axios.interceptors.response.use((response: AxiosResponse<any>):any => {
        endTime = new Date().getTime();
        timeElapsed = endTime - startTime
        const {userId, email} = userSession;

        if ( timeElapsed !== undefined && timeElapsed > 800 ){
            toast({
                    itemID: '1',
                    variant: 'destructive',
                    title: 'Slow Internet',
                    description: 'Your internet connection was detected slow. Might cause operation problems'
            });

            logger.warn(`Warning: User [${userId}:${email}] has slow internet, at endpoint: ${requestUrl}, elapsed time: ${timeElapsed}`, {
                componentName: 'CheckConnectionPerformance',
                additionalContext:  response.statusText,
            });
        }
        return response;
    });
}

export default checkConnectionPerformance;
