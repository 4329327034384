import routes from "../../constants/routes";
import axios from "axios";
import { Account } from "../../pages/Settings/Shared/Entities/Account";

type AccountsProps = {
  accounts?: Account[];
  error?: { message: string };
};

const getSalesforceAccountsAsync = async (
  userId: string,
  orgId: string,
  custom = false
): Promise<AccountsProps> => {
  try {
    const salesforcesAccounts = await axios.get<Account[]>(
      `${
        custom
          ? routes.get.customHubspot.getLinkedAccounts
          : routes.get.hubspot.getLinkedAccounts
      }/${orgId}/${userId}`
    );

    return { accounts: salesforcesAccounts?.data };
  } catch (error) {
    return { error: { message: "Error while getting sequences" } };
  }
};

export default getSalesforceAccountsAsync;
