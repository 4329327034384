export const validateContacts = (contacts: any[]) => {
    const combinationCount: { [key: string]: number } = {};
    const combinationCountPhone: { [key: string]: number } = {};
  
    contacts.forEach((contact: any) => {
      const key = `${contact.name}`;
      const keyPhone = `${contact.phone}`;
      combinationCount[key] = (combinationCount[key] || 0) + 1;
      combinationCountPhone[keyPhone] =
        (combinationCountPhone[keyPhone] || 0) + 1;
    });
  
    const phoneRegex = /^(?:\+{1,}\d{1,3}[\s.-]?\d{3}[\s.-]?\d{3}[\s.-]?\d{4})(?:\s*(?:ext|EXT|Ext)\.?\s*\d{1,5})?$|^(?:\(\+?\d{1,3}\)[\s.-]?\d{3}[-.\s]?\d{3}[-.\s]?\d{4})(?:\s*(?:ext|EXT|Ext)\.?\s*\d+)?$|^(?:\(\d{3}\)[\s.-]?\d{3}[-.\s]?\d{4})(?:\s*(?:ext|EXT|Ext)\.?\s*\d+)?$|^\d{3}[\s.-]?\d{3}[\s.-]?\d{4}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    contacts.forEach((contact: any) => {
        const errors: string[] = [];
        const repeatTimes = combinationCount[`${contact.name}`];
        const numberRepeat = combinationCountPhone[`${contact.phone}`];

        if (
            !contact.phone || 
            contact.phone.trim() === '' || 
            !phoneRegex.test(contact.phone)
        ) {
            errors.push('Invalid phone number format.');
        }
  
      if (
        !contact.email ||
        contact.email.trim() === '' ||
        !emailRegex.test(contact.email)
      ) {
        errors.push('Invalid email format.');
      }
  
      if (repeatTimes > 1) {
        errors.push(`Name repeat ${repeatTimes} times`);
      }
  
      if (numberRepeat > 1) {
        errors.push(`Phone repeat ${numberRepeat} times`);
      }
  
      if (errors.length > 0) {
        contact.errorMessages = errors;
      }
    });

    return contacts;
  };  